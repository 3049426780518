import { AppConfigService } from '../../services/app-config.service';
import { AuthService } from '../../services/auth.service';
import { GapiService } from '../../services/gapi.service';
import { ContextService } from '../../services/context.service';

export const appConfigService = new AppConfigService();
const appConfigInitializer: Promise<void> = appConfigService.loadAppConfig();

/**
 * appConfigInitializerFactory should be used as an initializer for any Angular
 * app that depends on {@link appConfigService} and/or {@link authService}. See
 * docs for how to use: https://angular.io/api/core/APP_INITIALIZER#usage-notes
 *
 * @returns a factory function that just returns a Promise that resolves when
 *   app config has been loaded
 */
export const appConfigInitializerFactory = () => () => appConfigInitializer;

export const authService = new AuthService(new GapiService(appConfigService));

export const contextService = new ContextService();
