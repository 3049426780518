import { Observable, ReplaySubject } from 'rxjs';

type CareOrgResourceName = string;

export class ContextService {
  private activeCareOrg = new ReplaySubject<CareOrgResourceName | null>(1);

  // Indicates if the activeCareOrg has been set because a ReplaySubject does
  // not have an initial value. Calling getActiveCareOrg with pipe and subscribe
  // without an initial value will not run
  private _hasActiveCareOrg = false;

  constructor() {}

  setActiveCareOrg(careOrg: CareOrgResourceName | null): void {
    this.activeCareOrg.next(careOrg);
    this._hasActiveCareOrg = true;
  }

  getActiveCareOrg(): Observable<CareOrgResourceName | null> {
    return this.activeCareOrg;
  }

  hasActiveCareOrg(): boolean {
    return this._hasActiveCareOrg;
  }
}
