import { AppConfigService } from 'projects/common/cross-frontend/app-config.types';

/**
 * Wrapper class for one or more gapi APIs. Some objects, like the GoogleAuth
 * object, cannot be returned as the subject of a promise since it contains
 * its own .then method which returns an object with the same type which
 * then causes infinite recursion. This wrapper prevents this by adding a
 * layer of indirection.
 */
export class GapiBindings {
  constructor(public auth: gapi.auth2.GoogleAuth) {}
}
/**
 * Service abstraction on top of the gapi global library.
 *
 * Having this allows easy mocking of initializing and loading
 * any gapi APIs instead of having to deal with loading global
 * libraries all over the code base.
 */
export class GapiService {
  public constructor(private readonly appConfigService: AppConfigService) {}

  getApis(): Promise<GapiBindings> {
    return new Promise((resolve) => {
      gapi.load('client', async () => {
        await this.appConfigService.loadAppConfig();

        await gapi.client.init({
          clientId: this.appConfigService.getConfig().clientId,
          scope: this.appConfigService.getConfig().scopes,
        });
        const googleAuth: gapi.auth2.GoogleAuth = gapi.auth2.getAuthInstance();
        resolve(new GapiBindings(googleAuth));
      });
    });
  }
}
