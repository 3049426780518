import axios, { AxiosResponse } from 'axios';
import { EnvironmentConfig } from 'projects/common/cross-frontend/app-config.types';

/**
 * Service to loads JSON config from server.
 */
export class AppConfigService {
  private config?: EnvironmentConfig;

  constructor() {}

  loadAppConfig(): Promise<void> {
    return axios.get('/env.json').then((response: AxiosResponse) => {
      this.config = response.data as EnvironmentConfig;
      console.info('Config loaded');
    });
  }

  getConfig() {
    if (this.config !== undefined) {
      return this.config;
    } else {
      throw new Error('Config not loaded.');
    }
  }
}
